import React, { useState } from "react";
import {
    Box,
    Flex,
    Input,
    Button,
    Heading,
    Image,
    InputGroup,
    InputRightElement,
} from "@chakra-ui/react";
import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    FormHelperText,
    FormErrorIcon,
} from "@chakra-ui/form-control";
import { useNavigate } from "react-router-dom";
import background from "../assets/images/background-login.jpg";
import eyes from "../assets/images/eyes.png";
import "../pages/Login.css";

const Login = () => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    return (
        <Flex
            h="100vh"
            align="center"
            justify="center"
            bgImage={`url(${background})`}
            bgSize="cover"
            bgRepeat="no-repeat"
        >
            <Box
                bg="white"
                p="40"
                borderRadius="md"
                boxShadow="lg"
                maxW="500px"
                w="420px"
            >
                {/* Title */}
                <Heading as="h2" size="lg" textAlign="center" mb="6"
                    style={{ fontFamily: "Inter", 
                            fontSize: "26px",
                            fontWeight: "600",
                            lineHeight: "31.47px",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                            color: "#08142D" }}>
                        Đăng nhập
                </Heading>

                {/* Form */}
                <form>
                    {/* Username */}
                    <FormControl id="username" mb="4">
                        <FormLabel 
                            style={{ 
                                display: "block",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "14.52px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#51607B",
                                marginBottom: "12px",
                            }}
                        >
                            Tên đăng nhập
                        </FormLabel>
                        <Input type="text" placeholder="Nhập số điện thoại" autocomplete="username" style={{width: "100%",
                            height: "24px",
                            borderBottom: "1px solid #DAE0EB",
                            borderTop: "none",
                            borderLeft: "none",
                            borderRight: "none",
                            fontFamily: "Inter",
                            fontSize: "14px",
                            fontWeight: "500",
                            lineHeight: "16.94px",
                            textAlign: "left",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                            color: "#000000",}}
                        />
                    </FormControl>

                    {/* Password */}
                    <FormControl id="password" mb="6">
                        <FormLabel
                            style={{ 
                                display: "block",
                                fontFamily: "Inter",
                                fontSize: "12px",
                                fontWeight: "500",
                                lineHeight: "14.52px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#51607B",
                                marginBottom: "12px",
                                marginTop:"16px"
                            }}
                        >
                            Mật khẩu
                        </FormLabel>
                        <InputGroup>
                            <Input
                                type={showPassword ? "text" : "password"}
                                placeholder="Nhập mật khẩu"
                                autocomplete="current-password"
                                style={{width: "100%",
                                height: "24px",
                                borderBottom: "1px solid #DAE0EB",
                                borderTop: "none",
                                borderLeft: "none",
                                borderRight: "none",
                                fontFamily: "Inter",
                                fontSize: "14px",
                                fontWeight: "500",
                                lineHeight: "16.94px",
                                textAlign: "left",
                                textUnderlinePosition: "from-font",
                                textDecorationSkipInk: "none",
                                color: "#000000"}}
                            />
                            <InputRightElement>
                                <Image
                                    src={eyes}
                                    alt="Toggle Password Visibility"
                                    cursor="pointer"
                                    onClick={togglePasswordVisibility}
                                    boxSize="20px"
                                />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>

                    {/* Submit Button */}
                    <Button
                        type="submit"
                        colorScheme="blue"
                        width="full"
                        mt="4"
                        style={{
                            width: "420px",
                            height: "48px",
                            padding: "15px 0px",
                            borderRadius: "8px",
                            border: "none",
                            background: "linear-gradient(258.94deg, #1ADDD2 0%, #0094CC 100.81%)",
                            fontFamily: "Inter",
                            fontSize: "15px",
                            fontWeight: "700",
                            lineHeight: "18.15px",
                            textUnderlinePosition: "from-font",
                            textDecorationSkipInk: "none",
                            color: "#fff",
                            cursor: "pointer",
                            marginTop: "55px",
                        }}
                    >
                        Đăng nhập
                    </Button>
                </form>
            </Box>
        </Flex>
    );
};

export default Login;
